import * as THREE from './build/three.module.js';
import App from './App.js';
import { Loader }from '../js/utils/Loader.js';
import Button from './Button.js';
import Step from './navigator/Step.js';
import Navigator from './navigator/Navigator.js';


export default class Visit extends Step  {

    constructor(data, camera) {
        super();

        

        this.data = data;
        this.camera = camera;
        //console.log(this.data[0]);

        this.texture;
        this.sphere;

        this.buttons = [];

        this.nextRotation = 0;
        this.isOn = false;

        

        this.fadeInTime = 0.25;
        this.fadeOutTime = 0.25;

        this.roomsDictionnary = {};
        this.data.rooms.forEach(element => {
            this.roomsDictionnary[element.id] = {texture : null, buttons : element.buttons, rotation : element.rotation}
        });
        //console.log(this.roomsDictionnary);


        this.currentRoomId = this.data.rooms[App.firstVisitId].id;
        this.currentRoom = this.roomsDictionnary [this.currentRoomId];
        this.loader = new Loader();
        this.loader.add(this.currentRoomId ,  App.imagesPath + "360/" + this.currentRoomId + " - " + App.INSTANCE.currentLanguage.toUpperCase() + ".jpg");
        this.loader.load(this.onLoaded.bind(this));
        this.init();

        App.INSTANCE.events.onEnableControls.dispatch();
    }

    
    init(){
        
        const geometry1 = new THREE.SphereBufferGeometry( 500, 32, 32 );
        // invert the geometry on the x-axis so that all of the faces point inward
        geometry1.scale( - 1, 1, 1 );

        const uvs1 = geometry1.attributes.uv.array;

        for ( let i = 0; i < uvs1.length; i += 2 ) {

            //uvs1[ i ] *= 0.5;

        }

        this.material = new THREE.MeshBasicMaterial( { map: this.texture, transparent : true, opacity : 0} );
        this.sphere = new THREE.Mesh( geometry1, this.material );
        this.sphere.rotation.order = 'YXZ';
        this.add(this.sphere);
        if(!App.debugRay){
            //console.log(this.currentRoomId.rotation);
            this.sphere.rotation.y = this.currentRoom.rotation;
        }
        //console.log("--------------" + this.currentRoom.rotation + "-----------------------");
        this.sphere.renderOrder = -10;
    }

    onLoaded(){
        this.texture = this.loader.get(this.currentRoomId);
        this.currentRoom.texture = this.texture;
        this.material.map = this.texture;
        this.material.needsUpdate = true;
        this.checkButtons();
        if(!this.isOn){
            this.on();
        }
        
    }

    checkButtons(){
        let textureToLoad = [];
        this.currentRoom.buttons.forEach(element => {
            if(this.roomsDictionnary[element.id].texture == null){
                textureToLoad.push(element.id);
            }
        });
        //console.log(textureToLoad);
        if(textureToLoad.length){
            for (let i = 0; i < textureToLoad.length; i++) {
                this.loader.add(textureToLoad[i],  App.imagesPath + "360/" + textureToLoad[i] + " - " + App.INSTANCE.currentLanguage.toUpperCase() + ".jpg");
            }
            this.loader.load(this.onTexturesLoaded.bind(this));
        }
        else{
            this.createButtons();
        }   
    }

    onTexturesLoaded(){
        this.currentRoom.buttons.forEach(element => {
               this.roomsDictionnary[element.id].texture = this.loader.get(element.id);
               //console.log("loaded " + element.id);
        });
        this.createButtons();
    }

    createButtons(){
        let actionButton;
        this.currentRoom.buttons.forEach(element => {
            actionButton = new Button( element.id, 40, 'circle', false, this.onActionButtonClicked.bind(this));
            actionButton.position.set(element.position.x, element.position.y, element.position.z);
            this.sphere.add(actionButton);
            actionButton.lookAt(0,actionButton.position.y,0);
            this.buttons.push(actionButton);
            actionButton.on();

        });
    }

    onActionButtonClicked(id){
        this.currentRoomId = id;
        this.currentRoom = this.roomsDictionnary [this.currentRoomId];
        this.texture = this.currentRoom.texture;
        //console.log(this.texture);
        this.moveOff();

        this.buttons.forEach(element => {
            element.destroy();
        });
        this.buttons = [];
        this.nextRotation = this.currentRoom.rotation;

    }


    moveOn(){
        this.material.opacity = 0;
        TweenMax.to(this.material, this.fadeInTime, { opacity : 1,  ease: Linear.easeNone, delay : 1, onComplete : this.moveOnComplete.bind(this), callBackScope : this});
    }

    moveOnComplete(){
        this.checkButtons();
    }

    moveOff(){

        TweenMax.to(this.material, this.fadeOutTime, { opacity : 0,  ease: Linear.easeNone, onComplete : this.moveOffComplete.bind(this), callBackScope : this});
    }

    moveOffComplete(){
        
        this.material.map = this.texture;
        this.material.needsUpdate = true;
        this.sphere.rotation.y = this.nextRotation;
        //console.log("--------------" + this.nextRotation + "-----------------------");
        //console.log(this.nextRotation);
        this.moveOn();
    }

    update(){
        
    }

    turnLeft(){
        this.sphere.rotateY(0.1);
        //console.log(this.sphere.rotation.y + " " + this.camera.rotation.y);
    }

    turnRight(){
        this.sphere.rotateY(-0.1);
        //console.log(this.sphere.rotation.y + " " + this.camera.rotation.y);
    }


    on(){
        super.on();
        this.material.opacity = 0;
        if(this.texture == null){
            return;
        }
        //console.log("on");
        this.isOn = true;

        

        TweenMax.to(this.material, this.fadeInTime, { opacity : 1,  ease: Linear.easeNone, delay : 0.2});
        TweenMax.to(this.video, this.fadeInTime, { volume : 1,  ease: Linear.easeNone, delay : 0.2, onComplete : this.onComplete, callbackScope : this});
    }

    onComplete(){
        super.onComplete();
    }

    off(){
        TweenMax.to(this.material, this.fadeOutTime, { opacity : 0,  ease: Linear.easeNone});
        TweenMax.to(this.video, this.fadeOutTime/2, { volume : 0,  ease: Linear.easeNone, delay : this.fadeOutTime/2, onComplete : this.offComplete, callbackScope : this});
    }

    offComplete(){
        //console.log("offComplete");
        super.offComplete();
    }

    clear(){
        super.clear();
    }

    


}
