
import { Utils } from '../utils/Utils.js';
import { Step } from '../navigator/Step.js';
import Home from '../Home.js';
import VideoPlayerStep from '../VideoPlayerStep.js';
import VideoPreloader from '../VideoPreloader.js';
import Visit from '../Visit.js';
import App from '../App.js';
import MiniNav from '../MiniNav.js';

export default class Navigator {

    static StepType = {
        Video : "video",
        Visit : "visit",
        Home : "home"
      }

    constructor(data, scene, firstStep) {
        this.data = data;
        this.scene = scene;

        Navigator.INSTANCE = this;

        console.log(this.data);

        this.stepsCount = this.data.length;

        this.currentStep = firstStep ? firstStep : "0";
        this.currentStep = "home";
        this.currentData = this.data[this.currentStep];

        this.events = {};
        this.VideoPreloader = new VideoPreloader();

        this.events.onNextStep = new signals.Signal();
        this.events.onNextStep.add(this.onNextStep, this);
        this.events.onPreviousStep = new signals.Signal();
        this.events.onPreviousStep.add(this.onPreviousStep, this);
        this.events.onStepOffComplete = new signals.Signal();
        this.events.onStepOffComplete.add(this.onStepOffComplete, this);
        this.events.onCheckPreload = new signals.Signal();
        this.events.onCheckPreload.add(this.checkVideoPreload, this);

        if(!this.isVR){
            this.mininav = new MiniNav();
        }
    }

    start(){
        this.createStep();
    }

    createStep(){
        switch(this.currentData.type){
            case Navigator.StepType.Home :
                this.currentStepObject = new Home();
                this.currentStepObject.position.set(0,0, -100);
                this.checkVideoPreload();
                this.mininav.hide();
                break;
            case Navigator.StepType.Video :
                this.currentStepObject = new VideoPlayerStep( this.currentData);
                this.VideoPreloader.stop();
                this.mininav.show();
                break;
            case Navigator.StepType.Visit :
                this.currentStepObject = new Visit(this.currentData, App.INSTANCE.camera);
                this.mininav.show();
                break;
        }
        this.scene.add(this.currentStepObject);
    }

    checkVideoPreload(){
        const nextStep = this.currentData.next;
        const nextData = this.data[nextStep];
        if(nextData.type ==  Navigator.StepType.Video){
            this.VideoPreloader.preload(App.videosPath + App.INSTANCE.currentQuality +'/'+nextData.file + " " + App.INSTANCE.currentLanguage.toUpperCase() + ".mp4");
        }
    }

    onNextStep(index){
        if(App.debugRoomRotation && this.currentData.type == Navigator.StepType.Visit){
            this.currentStepObject.turnRight();
        }
        else{
            if(index){
                this.currentStep = index;
            }
            else{
                this.currentStep = this.currentData.next;
            }
            this.currentStepObject.off();
        }
    }

    onPreviousStep(){
        if(App.debugRoomRotation && this.currentData.type == Navigator.StepType.Visit){
            this.currentStepObject.turnLeft();
        }
        else{
            this.currentStep = this.currentData.previous;
            this.currentStepObject.off();
        }
    }

    checkStep(){
        this.currentData = this.data[this.currentStep];
    }

    onStepOffComplete(){
        this.scene.remove(this.currentStepObject);

        this.checkStep();
        this.createStep();
    }

    update(){
       if(this.currentStepObject){
        this.currentStepObject.update();
       }
    }

    nextDebug(){
        if(this.currentData.type == Navigator.StepType.Video){
            this.currentStepObject.goToEnd();
        }
        else{
            this.onNextStep();
        }
    }

}

