import App from './App.js';
import { Utils } from '../js/utils/Utils.js';
import Navigator from './navigator/Navigator.js';
export default class MiniNav  {

    constructor() {
        this.mainDiv = document.getElementById( 'nav' );
        this.mainDiv.style.display = "none";
        this.nextButton = document.getElementById( 'next' );
        this.previousButton = document.getElementById( 'previous' );

        this.nextButton.addEventListener('pointerdown', this.onNext.bind(this));
        this.previousButton.addEventListener('pointerdown', this.onPrevious.bind(this));
    }

    onNext(e){
        Navigator.INSTANCE.events.onNextStep.dispatch();
    }

    onPrevious(e){
        Navigator.INSTANCE.events.onPreviousStep.dispatch();
    }

    show(){
        this.mainDiv.style.display = "block";
    }

    hide(){
        this.mainDiv.style.display = "none";
    }
}

