import * as THREE from '../../js/build/three.module.js';

class Utils  {

    static visibleRecursive(parent, show){
        parent.visible = show;
        parent.children.forEach(element => this.visibleRecursive(element,show));
    }

    static detectMobile() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i,
            /Windows/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    static detectVRCompatible() {
        const toMatch = [
            /Quest/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
}

export { Utils };
