import * as THREE from '../../js/build/three.module.js';
import { Loader } from '../../js/utils/Loader.js';
import App from '../../js/App.js';
export default class Image extends THREE.Group {

    constructor(width, textureId,renderOrder) {

        super();
        this.width = width;
        this.height;
        this.plane;

        this.texture = App.INSTANCE.loader.get(textureId);
        this.renderOrder = renderOrder;


        this.material = new THREE.MeshBasicMaterial( { map : this.texture, transparent : true, depthWrite : false} );
        
        this.ratio = this.texture.image.width / this.texture.image.height;
        this.height = this.width/this.ratio;
        this.geometry = new THREE.PlaneGeometry( this.width ,  this.height, 1);
        this.plane = new THREE.Mesh( this.geometry, this.material );
        
        this.add(this.plane);

    }

    on(){
        this.material.opacity = 0;
        TweenMax.to(this.material, 0.3, { opacity : 1,  ease: Linear.easeNone});
    }

    off(){
        TweenMax.to(this.material, 0.3, { opacity : 0,  ease: Linear.easeNone});
    }


}

