import * as THREE from '../js/build/three.module.js';
import Image from '../js/utils/Image.js';
import Button from '../js/Button.js';
import App from '../js/App.js';
import { Utils } from '../js/utils/Utils.js';
import Step from './navigator/Step.js';
import Navigator from './navigator/Navigator.js';

export default class Home extends Step {

    constructor() {
        super();

        this.width = 100;
        this.height = 100;

        this.init();
        this.initState();
    }

    init(){


        this.backgroundFR = new Image( this.width,  "homeBackground_fr", -1);
        this.backgroundFR.position.set(0,0,0);
        this.add(this.backgroundFR);

        this.startButtonFR = new Button( 'start', 40, 'start_over_fr', true, this.onStart.bind(this));
        this.startButtonFR.position.set(0,-17,1);
        this.add(this.startButtonFR);

        App.INSTANCE.events.onDisableControls.dispatch();
        App.INSTANCE.events.onCenterControls.dispatch();
    }

    initState(){
        this.startButtonFR.show();

        this.add(this.backgroundFR);
    }

    select(){
        this.clickHandler();
    }

    onStart(){
        Navigator.INSTANCE.events.onNextStep.dispatch();
    }

    onClickLanguage(id){
        App.INSTANCE.currentLanguage = id;
        this.initState();
    }

    onClickQuality(id){
        App.INSTANCE.currentQuality = id;
        this.initState();
        Navigator.INSTANCE.events.onCheckPreload.dispatch();
    }

    on(){
        Utils.visibleRecursive(this, true);
        
    }

    off(){
        super.off();
        Utils.visibleRecursive(this, false);
        this.offComplete();
    }

    offComplete(){
        super.offComplete();    
    }

    clear(){
        this.startButtonFR.destroy();
    }
}

