import * as THREE from '../js/build/three.module.js';
import App from '../js/App.js';
import Step from './navigator/Step.js';
import Navigator from './navigator/Navigator.js';
import Button from './Button.js';

export default class VideoPlayerStep extends Step {

    constructor(data) {
        super();
   
        this.data = data;
        this.file = data.file + " " + App.INSTANCE.currentLanguage.toUpperCase() + ".mp4";

        this.video;
        this.texture;
        this.videoSphere
        this.isOn = false;

        this.fadeInTime = 0.5;
        this.fadeOutTime = 0.5;
        this.actionButtons = [];

        if(this.data.withoutFade){
            this.fadeInTime = 0;
        }

        this.isPlaying = false;
        
        this.endBehavior = this.data.end;
            
        this.dontFade = false;
        this.currentButtonsTargets = {};
        console.log("---------------");
        this.init();
    }

    init(){
        this.video = document.createElement("video");
        if(App.DEBUG){
            // this.video.muted = true;
        }
        this.video.preload="auto";
        this.video.playsInline = true;
        this.video.src = App.videosPath + App.INSTANCE.currentQuality +'/'+ this.file;
        
        
        //this.videoPreloader = document.getElementById( 'videoPreloader' );
        this.onCanPlayThroughHandler = this.onCanPlayThrough.bind(this);
        this.onCanPlayHandler = this.onCanPlay.bind(this);
        this.onVideoEndedHandler = this.onVideoEnded.bind(this);
        this.onBufferingHandler = this.onBuffering.bind(this);
        this.video.addEventListener('canplaythrough', this.onCanPlayThroughHandler, false);
        this.video.addEventListener('canplay', this.onCanPlayHandler, false);
        this.video.addEventListener("ended", this.onVideoEndedHandler);
        this.video.addEventListener("waiting", this.onBufferingHandler);
        this.texture = new THREE.VideoTexture( this.video );

        //this.videoPreloader.addEventListener('progress', this.onProgress.bind(this), false);

        const geometry1 = new THREE.SphereBufferGeometry( 1000, 32, 32 );
        // invert the geometry on the x-axis so that all of the faces point inward
        geometry1.scale( - 1, 1, 1 );

        const uvs1 = geometry1.attributes.uv.array;

        for ( let i = 0; i < uvs1.length; i += 2 ) {
            //uvs1[ i ] *= 0.5;
        }
        this.material = new THREE.MeshBasicMaterial( { map: this.texture, transparent : true, opacity : 0} );
        this.videoSphere = new THREE.Mesh( geometry1, this.material );
        this.videoSphere.rotation.y = - Math.PI / 2;
        if(this.data.rotation){
            this.videoSphere.rotation.y += this.data.rotation;
        }
        
        this.add(this.videoSphere);
        this.videoSphere.renderOrder = -10;

        App.INSTANCE.events.onEnableControls.dispatch();
        this.play();
    }

    onCanPlay(){
        // console.log("onCanPlay " + this.video.readyState);
        if(!this.isOn){
            this.on();
            //this.play();
            this.isOn = true;
        }
    }

    stop(){
        this.video.pause();
        this.video.seek(0);
    }

    onCanPlayThrough(){
        console.log("onCanPlayThrough " + this.video.readyState);
        this.currentDuration = this.video.duration;
    }

    onProgress(){

        return;
        var bufferedEnd = this.video.buffered.end(this.video.buffered.length - 1);
        var duration =  this.video.duration;
        if (duration > 0) {
            //document.getElementById('buffered-amount').style.width = ((bufferedEnd / duration)*100) + "%";
            //console.log("onProgress : " +  ((bufferedEnd / duration)*100) + "%");
        }
    }

    onBuffering(){
        //console.log("buffering " + this.video.readyState);
    }

    play(){
        this.isPlaying = true;
        this.video.currentTime = 0;
        this.currentDuration = 0;
        this.video.play();
        
    }

    onVideoEnded(){
        //Navigator.INSTANCE.events.onNextStep.dispatch();
    }

    update(){
        super.update();
        if(this.isPlaying){
            switch(this.endBehavior) {
                case 'pause' :
                    if(this.currentDuration > 0 && this.video.currentTime > this.currentDuration - 0.3){
                        this.makePauseAtTheEnd();
                    }
                    break;
                case 'next_end' :
                case 'next' :
                    if(this.currentDuration > 0 && this.video.currentTime > this.currentDuration - 0.2){
                        this.next();
                    }
                    break;
                default :
                    if(this.currentDuration > 0 && this.video.currentTime > this.currentDuration - this.fadeOutTime){
                        this.isPlaying = false;
                        this.off();
                    }
                    break;
            }
        }
    }

    next(){
        console.log('next');
        this.video.pause();
        this.isPlaying = false;
        super.next();
    }

    makePauseAtTheEnd(){
        console.log('makePauseAtTheEnd');
        this.video.pause();
        this.isPlaying = false;
        this.pauseAtTheEnd = false;
        this.checkEnd();
    }

    goToEnd(){
        if(this.isPlaying){
            this.video.currentTime = this.currentDuration-1;
        }
    }

    on(){
        super.on();
        if(this.dontFade){
            this.dontFade = false;
        }
        else{
            this.material.opacity = 0;
            this.video.volume = 0;
        }
        
        TweenMax.to(this.material, this.fadeInTime, { opacity : 1,  ease: Linear.easeNone, delay : 0.2});
        TweenMax.to(this.video, this.fadeInTime, { volume : 1,  ease: Linear.easeNone, delay : 0.2, onComplete : this.onComplete, callbackScope : this});
    }

    onComplete(){
        super.onComplete();
    }

    off(){
        TweenMax.to(this.material, this.fadeOutTime, { opacity : 0,  ease: Linear.easeNone});
        TweenMax.to(this.video, this.fadeOutTime/2, { volume : 0,  ease: Linear.easeNone, delay : this.fadeOutTime/2, onComplete : this.offComplete, callbackScope : this});
    }

    offComplete(){
        super.offComplete();
    }

    clear(){
        super.clear();
        this.video.pause();
        //this.video.src = null;

        this.video.removeEventListener('canplaythrough', this.onCanPlayThroughHandler, false);
        this.video.removeEventListener('canplay', this.onCanPlayHandler, false);
        this.video.removeEventListener("ended", this.onVideoEndedHandler);
        this.video.removeEventListener("waiting", this.onBufferingHandler);


        this.actionButtons.forEach(element =>{
            element.destroy();
        })
    }

    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    //--------------------------  END ----------------------------------
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------

    checkEnd(){
        if(this.data.buttons){
            this.data.buttons.forEach(element => {
                this.currentButtonsTargets[this.data.buttonId] = this.data.target;
                this.initActionButton(element);
            });
        }
        Navigator.INSTANCE.events.onCheckPreload.dispatch();
    }

    initActionButton(data){
        const actionButton = new Button( data.target, data.buttonSize, data.localizable ? App.INSTANCE.getLocalizedImge(data.buttonId) : data.buttonId, true, this.onActionButtonClicked.bind(this));
        actionButton.position.set(data.buttonPosition.x,data.buttonPosition.y,data.buttonPosition.z);
        this.add(actionButton);
        actionButton.lookAt(0,actionButton.position.y,0);
        this.actionButtons.push(actionButton);
    }

    onActionButtonClicked(id){
        this.actionButtons.forEach(element =>{
            element.off();
        })
        //this.fadeOutTime = 0;
        Navigator.INSTANCE.events.onNextStep.dispatch(id);
    }
}
