import * as THREE from '../js/build/three.module.js';
import Image from '../js/utils/Image.js';
import App from '../js/App.js';
import { Utils } from '../js/utils/Utils.js';
export default class Button extends THREE.Group {

    constructor(buttonId, width, textureId, hasOutState, clickHandler) {
        super();

        this.buttonId = buttonId;
        this.width = width;
        this.textureId = textureId;
        this.outTextureId = hasOutState ? this.textureId.replace("over", "out") : null
        this.clickHandler = clickHandler;
        this.isActive = false;

        this.isRadio = false;


        this.name = "my group";

        this.init();
    }

    init(){
        this.overImage = new Image( this.width,  this.textureId,0);
        this.overImage.position.set(0,0,0.1);
        this.add(this.overImage);

        if(this.outTextureId){
            this.outImage = new Image( this.width,  this.outTextureId,0);
            this.outImage.position.set(0,0,0.1);
            this.add(this.outImage);
            this.remove(this.overImage);
        }

        const overZoneMaterial = new THREE.MeshBasicMaterial( {opacity : 0,transparent : true} );
        const overZoneGeomertry = new THREE.PlaneGeometry( this.width ,  this.overImage.height, 1);
        this.overZone = new THREE.Mesh( overZoneGeomertry, overZoneMaterial);
        this.add(this.overZone);

        App.INSTANCE.isInteractive(this.overZone);

        this.overZone.button = this;
    }


    active(){
        this.add(this.overImage);
        this.remove(this.outImage);
        this.isActive = true;
    }

    deactive(){
        this.add(this.outImage);
        this.remove(this.overImage);
        this.isActive = false;
    }
    hide(){
        this.visible = false;
        this.overImage.visible = false;
        if(this.outImage){
            this.outImage.visible = false;
        }
    }

    hide(){
        Utils.visibleRecursive(this, false);
    }

    show(){
        Utils.visibleRecursive(this, true);
    }

    on(){
        if(this.outImage){
            this.outImage.on();
        }
        else{
            this.overImage.on();
        }
    }

    select(){
        if(!this.isActive){
            this.out();
            this.clickHandler(this.buttonId);
        }
    }

    over(){
        if(!this.isActive){
            const scaleFactor = 1.05;
            TweenMax.killTweensOf(this.scale);
            TweenMax.to(this.scale, 0.2, { x:scaleFactor, y:scaleFactor, z:scaleFactor,  ease: Back.easeOut.config(3)});
            if(!this.isRadio){
                if(this.outImage){
                    this.add(this.overImage);
                    this.remove(this.outImage);
                }
            }
        }
    }

    out(){
        if(!this.isActive){
            TweenMax.killTweensOf(this.scale);
            TweenMax.to(this.scale, 0.2, { x:1, y:1, z:1,  ease: Back.easeOut.config(3)});
            if(!this.isRadio){
                if(this.outImage){
                    this.add(this.outImage);
                    this.remove(this.overImage);
                }
            }
        }
    }

    off(){
        this.overImage.off();
        if(this.outImage){
            this.outImage.off();
        }
    }

    destroy(){
        //console.log("destroy");
        App.INSTANCE.removeFromInteractive(this.overZone);
        this.parent.remove(this);
    }
}
