import 'regenerator-runtime/runtime'
import App  from './js/App.js';
import { Navigator } from './js/navigator/Navigator.js';


let data = {
  
  steps : {
    ["home"]: {
      type : "home",
      next : "intro",
    },
    ["intro"]: {
      type : "video",
      file: "Colissimo Inside (Court)",
      end : "next_end",
      next : "home",
      previous : "home",
    },
  },
}
const params = {};
const url = new URL(window.location.href);
let searchParams = new URLSearchParams(url.search);
searchParams.forEach(function(value, key) {
     if(value == "false"){
         value = false;
     }
     else if(value == "true"){
         value = true;
     }
     else if (!isNaN(Number(value)) ){
    	 value = Number(value);
     }
    params[key] = value;
});



let app = new App(data,params);




