import * as THREE from '../../js/build/three.module.js';
class Loader  {

    constructor() {
        this.loadedCallBack;
        this.queue = [];
        this.current = {};

        this.cache = {};

        this.loader = new THREE.TextureLoader();
        //this.loader.load(texturePath, this.onComplete.bind(this), null, this.onError.bind(this));

    }

    add(id, url){
        //console.log(url);
        this.queue.push({id, url});
    }

    load(callback){
        this.loadedCallBack = callback;

        this.loadQueue();
    }

    loadQueue(){
        if(this.queue.length==0){
            this.loadedCallBack();
            return;
        }
        this.current = this.queue.shift();
        this.loader.load(this.current['url'], this.onComplete.bind(this), null, this.onError.bind(this));
    }

    onComplete(texture){
        this.cache[this.current['id']] = texture;
        this.loadQueue();
    }

    onError(){
        console.log(this.current['id'] + " error");
        this.cache[this.current['id']] = null;
        this.loadQueue();
    }

    get(id){
        return this.cache[id] ;
    }


}

export { Loader };
