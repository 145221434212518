

export default class Navigator {

  

    constructor() {
      this.onCanPlayHandler = this.onCanPlay.bind(this);

      this.video = document.createElement("video");
      this.video.addEventListener('canplay', this.onCanPlayHandler, false);
      this.video.muted = true;
      this.video.preload="auto";
      this.video.playsInline = true;

      //document.body.appendChild(this.video );
    }

    onCanPlay(){
      //console.log(this.video.readyState);
    }


    preload(file){
      this.video.src = file;
      this.video.play();
    }

    stop(){
      this.video.pause();

    }

}